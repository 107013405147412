/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";
import ConfigProvider from "../plugins/config-provider";

const getDefaultState = () => {
  return {
    currentUser: {}
  };
};
const state = getDefaultState();

const getters = {
  getCurrentUser: state => state.currentUser
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  }
};

const actions = {
  async createUser({ commit }, { userInfo }) {
    try {
      const response = await services.UserService.createUser(userInfo);
      return response;
    } catch (error) {
      console.log(error.response?.body || error);
      if (ConfigProvider.value("domiAppType") === "1BOARD") {
        return Promise.reject(error.response?.body || error);
      }
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  },
  async updateUser({ commit }, { userId, userInfo }) {
    try {
      await services.UserService.updateUser(userId, userInfo);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteUser({ commit }, userId) {
    try {
      await services.UserService.deleteUser(userId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async identityConfirm({ commit }, parameters) {
    try {
      await services.UserService.identityConfirm(parameters);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async removeUserFromCondominium({ commit }, { userId, condominiumId }) {
    try {
      await services.UserService.removeUserFromCondominium(
        userId,
        condominiumId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async setUserPassword({ commit }, payload) {
    try {
      const tool = "email";
      const response = await services.UserService.confirmUser(tool, payload);
      return response;
    } catch (error) {
      // console.log(error.response?.body || error);
      // SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  },
  async uploadCondominium({ commit, rootGetters }, file) {
    try {
      const condominiuId = rootGetters["condominium/getCurrentCondominium"]._id;
      await services.UserService.uploadCondominium(condominiuId, file);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addUserCondominium({ commit }, { userId, condominiumId, payload }) {
    try {
      await services.UserService.addUserCondominium(
        userId,
        condominiumId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateUserCondominium({ commit }, { userId, condominiumId, payload }) {
    try {
      await services.UserService.updateUserCondominium(
        userId,
        condominiumId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async suspendUser({ commit }, userId) {
    try {
      await services.UserService.suspendUser(userId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async reactivateUser({ commit }, userId) {
    try {
      await services.UserService.reactivateUser(userId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
