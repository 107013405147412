/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";
import { userCommunicationGroupFilters } from "../constants/user";

const getDefaultState = () => {
  return {
    condominiumUsers: [],
    filters: {
      search: "",
      status: null,
      condominium: null
    },

    communications: [],
    communication: {},
    userCommunications: [],
    communicationForm: {
      type: "standard",
      subject: "",
      body: "",
      documents: []
    },
    recipients: [],
    settings: {
      readReceipt: false,
      emailToAppUsers: false,
      paperToMailUsers: false
    },
    prevRouteName: "Communications",
    editRecipients: {
      add: [],
      remove: [],
      selected: []
    },
    editDocuments: {
      add: [],
      remove: []
    },
    activeGroups: [],
    groupUserFilters: userCommunicationGroupFilters,
    users: []
  };
};
const state = getDefaultState();

const getters = {
  getCondominiumUsers: state => state.condominiumUsers,

  getFilterSearch: state => state.filters.search,
  getFilterStatus: state => state.filters.status,
  getFilterCondominium: state => state.filters.condominium,

  getCommunications: state => state.communications,
  getCommunication: state => state.communication,
  getUserCommunications: state => state.userCommunications,

  getRecipients: state => state.recipients,
  getDocuments: state => state.communicationForm.documents,
  getCommunicationForm: state => state.communicationForm,
  getSettings: state => state.settings,
  getPrevRouteName: state => state.prevRouteName,
  getEditRecipients: state => state.editRecipients,
  getEditDocuments: state => state.editDocuments,

  getActiveGroups: state => state.activeGroups,
  getGroupUserFilters: state => state.groupUserFilters,
  getUsers: state => state.users
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_CONDOMINIUM_USERS(state, condominiumUsers) {
    state.condominiumUsers = condominiumUsers;
  },

  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_STATUS(state, status) {
    state.filters.status = status;
  },
  SET_FILTER_CONDOMINIUM(state, condominium) {
    state.filters.condominium = condominium;
  },

  SET_COMMUNICATIONS(state, communications) {
    state.communications = communications;
  },
  SET_COMMUNICATION(state, communication) {
    state.communication = communication;
  },
  SET_USER_COMMUNICATIONS(state, userCommunications) {
    state.userCommunications = userCommunications;
  },

  SET_FORM_TYPE(state, type) {
    state.communicationForm.type = type;
  },
  SET_FORM_SUBJECT(state, subject) {
    state.communicationForm.subject = subject;
  },
  SET_FORM_BODY(state, body) {
    state.communicationForm.body = body;
  },
  SET_FORM_DOCUMENTS(state, documents) {
    state.communicationForm.documents = documents;
  },
  RESET_DOCUMENTS(state) {
    state.communicationForm.documents = [];
  },
  SET_FORM_RECIPIENTS(state, recipients) {
    state.recipients = recipients;
  },
  RESET_RECIPIENTS(state) {
    state.recipients = [];
  },
  SET_SETTINGS_READ_CONFIRMATION(state, readReceipt) {
    state.settings.readReceipt = readReceipt;
  },
  SET_SETTINGS_APP(state, emailToAppUsers) {
    state.settings.emailToAppUsers = emailToAppUsers;
  },
  SET_SETTINGS_PAPPER(state, paperToMailUsers) {
    state.settings.paperToMailUsers = paperToMailUsers;
  },
  SET_PREV_ROUTE_NAME(state, routeName) {
    if (routeName === "CondominiumManagement") state.prevRouteName = routeName;
  },
  SET_RECIPIENTS_TO_ADD(state, recipients) {
    state.editRecipients.add = recipients;
  },
  SET_RECIPIENTS_TO_REMOVE(state, recipients) {
    state.editRecipients.remove = recipients;
  },
  SET_RECIPIENTS_SELECTION(state, recipients) {
    state.editRecipients.selected = [...recipients];
  },
  SET_DOCUMENTS_TO_ADD(state, documents) {
    state.editDocuments.add = documents;
  },
  SET_DOCUMENTS_TO_REMOVE(state, documents) {
    state.editDocuments.remove = documents;
  },
  SET_ACTIVE_GROUPS(state, groups) {
    state.activeGroups = groups;
  },
  SET_GROUP_USER_FILTERS(state, filters) {
    state.groupUserFilters = filters;
  },
  SET_USERS(state, users) {
    state.users = users;
  }
};

const actions = {
  async retrieveCondominiumUsers({ commit, getters }, condominiumId) {
    try {
      const parameters = {
        // search: getters.getFilterSearch
      };
      const response = await services.UserService.retrieveUsers({
        condominiumId: condominiumId,
        ...parameters
      });
      commit("SET_CONDOMINIUM_USERS", response);
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.issue.noCondominiumErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async retrieveCommunicationByToken({ commit }, { token }) {
    try {
      const response = await services.CommunicationService.retrieveCommunicationByToken(
        token
      );
      return response;
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async readCommunicationByToken({ commit }, { token, payload }) {
    try {
      const response = await services.CommunicationService.readCommunicationByToken(
        token,
        payload
      );
      return response;
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createCommunication({ commit }, { condominiumId, payload }) {
    try {
      const response = await services.CommunicationService.createCommunication(
        condominiumId,
        payload
      );
      commit("SET_COMMUNICATION", response);
      return response;
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCommunications({ commit }, { condominiumId }) {
    try {
      const response = await services.CommunicationService.retrieveCommunications(
        condominiumId
      );
      commit("SET_COMMUNICATIONS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCommunication({ commit }, { condominiumId, communicationId }) {
    var recipientData = [];
    var documentData = [];
    try {
      const response = await services.CommunicationService.retrieveCommunication(
        condominiumId,
        communicationId
      );
      response.communication.recipients.forEach(recipient => {
        recipientData.push(recipient.recipientId._id);
      });
      response.communication.documents.forEach(document => {
        documentData.push(document.filename);
      });
      commit("SET_COMMUNICATION", response.communication);
      commit("SET_FORM_SUBJECT", response.communication.subject);
      commit("SET_FORM_BODY", response.communication.body);
      commit(
        "SET_SETTINGS_READ_CONFIRMATION",
        response.communication.readReceipt
      );
      commit("SET_SETTINGS_APP", response.communication.emailToAppUsers);
      commit("SET_SETTINGS_PAPPER", response.communication.paperToMailUsers);
      commit("SET_FORM_RECIPIENTS", recipientData);
      commit("SET_FORM_DOCUMENTS", documentData);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Communications" });
      return Promise.reject();
    }
  },
  async updateCommunication(
    { commit },
    { condominiumId, communicationId, payload }
  ) {
    try {
      const response = await services.CommunicationService.updateCommunication(
        condominiumId,
        communicationId,
        payload
      );
      commit("SET_COMMUNICATION", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteCommunication({ commit }, { condominiumId, communicationId }) {
    try {
      await services.CommunicationService.deleteCommunication(
        condominiumId,
        communicationId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveUserCommunications({ commit, getters, rootGetters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch
      };
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }
      if (getters.getFilterStatus) {
        parameters.status = getters.getFilterStatus;
      }
      if (getters.getFilterCondominium) {
        parameters.condominiumId = getters.getFilterCondominium;
      }
      const response = await services.CommunicationService.retrieveUserCommunications(
        parameters
      );
      commit("SET_USER_COMMUNICATIONS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addCommunicationRecipients(
    { commit },
    { condominiumId, communicationId, payload }
  ) {
    try {
      await services.CommunicationService.addCommunicationRecipients(
        condominiumId,
        communicationId,
        payload
      );
      // commit("SET_COMMUNICATION_RECIPIENT", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createCommunicationRecipient(
    { commit },
    { condominiumId, communicationId, payload }
  ) {
    try {
      await services.CommunicationService.createCommunicationRecipient(
        condominiumId,
        communicationId,
        payload
      );
      // commit("SET_COMMUNICATION_RECIPIENT", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateCommunicationRecipient(
    { commit },
    { condominiumId, communicationId, recipientId, payload }
  ) {
    try {
      await services.CommunicationService.updateCommunicationRecipient(
        condominiumId,
        communicationId,
        recipientId,
        payload
      );
      // commit("SET_COMMUNICATION_RECIPIENT", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteCommunicationRecipient(
    { commit },
    { condominiumId, communicationId, recipientId }
  ) {
    try {
      await services.CommunicationService.deleteCommunicationRecipient(
        condominiumId,
        communicationId,
        recipientId
      );
      // commit("SET_COMMUNICATION_RECIPIENT", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addDocuments({ commit }, { condominiumId, communicationId, payload }) {
    try {
      await services.CommunicationService.addDocuments(
        condominiumId,
        communicationId,
        payload
      );
      // commit("SET_COMMUNICATION_DOCUMENTS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteDocument(
    { commit },
    { condominiumId, communicationId, documentId }
  ) {
    try {
      await services.CommunicationService.deleteDocument(
        condominiumId,
        communicationId,
        documentId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async sendCommunication({ commit }, { condominiumId, communicationId }) {
    try {
      await services.CommunicationService.sendCommunication(
        condominiumId,
        communicationId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async receiptsReport({ commit }, { condominiumId, communicationId }) {
    try {
      const response = await services.CommunicationService.receiptsReport(
        condominiumId,
        communicationId
      );
      const blobURL = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = "RecipientsReport.pdf";
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {
        messageText: "page.communication.details.norecipients"
      });
      return Promise.reject();
    }
  },
  async paperCommunication({ commit }, { condominiumId, communicationId }) {
    try {
      const response = await services.CommunicationService.paperCommunication(
        condominiumId,
        communicationId
      );
      const blobURL = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = "RecipientsPaper.pdf";
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveGroupUsers({ commit, getters }, extraParams = {}) {
    try {
      const status = getters.getGroupUserFilters.status.join(",");
      const groups = getters.getGroupUserFilters.groups.join(",");
      const response = await services.UserService.retrieveUsers({
        status,
        groups,
        ...extraParams
      });
      commit("SET_USERS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveActiveGroups({ commit }) {
    try {
      const response = await services.GroupService.retrieveGroups();
      commit("SET_ACTIVE_GROUPS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createCommunicationWithoutCondominium({ commit }, { payload }) {
    try {
      const response = await services.CommunicationService.createCommunicationWithoutCondominium(
        payload
      );
      commit("SET_COMMUNICATION", response);
      return response;
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateCommunicationPure({ commit }, { communicationId, payload }) {
    try {
      const response = await services.CommunicationService.updateCommunicationPure(
        communicationId,
        payload
      );
      commit("SET_COMMUNICATION", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveCommunicationPure({ commit }, { communicationId }) {
    var recipientData = [];
    var documentData = [];
    try {
      const response = await services.CommunicationService.retrieveCommunicationPure(
        communicationId
      );
      const communication = response.body.communication;
      communication.recipients.forEach(recipient => {
        recipientData.push(recipient.recipientId._id);
      });
      communication.documents.forEach(document => {
        documentData.push(document.filename);
      });
      commit("SET_COMMUNICATION", communication);
      commit("SET_FORM_SUBJECT", communication.subject);
      commit("SET_FORM_BODY", communication.body);
      commit("SET_SETTINGS_READ_CONFIRMATION", communication.readReceipt);
      commit("SET_SETTINGS_APP", communication.emailToAppUsers);
      commit("SET_SETTINGS_PAPPER", communication.paperToMailUsers);
      commit("SET_FORM_RECIPIENTS", recipientData);
      commit("SET_FORM_DOCUMENTS", documentData);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Communications" });
      return Promise.reject();
    }
  },

  async addCommunicationRecipientsPure(
    { commit },
    { communicationId, payload }
  ) {
    try {
      await services.CommunicationService.addCommunicationRecipientsPure(
        communicationId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteCommunicationRecipientPure(
    { commit },
    { communicationId, recipientId }
  ) {
    try {
      await services.CommunicationService.deleteCommunicationRecipientPure(
        communicationId,
        recipientId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addDocumentsPure({ commit }, { communicationId, payload }) {
    try {
      await services.CommunicationService.addDocumentsPure(
        communicationId,
        payload
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deleteDocumentPure({ commit }, { communicationId, documentId }) {
    try {
      await services.CommunicationService.deleteDocumentPure(
        communicationId,
        documentId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async sendCommunicationPure({ commit }, { communicationId }) {
    try {
      await services.CommunicationService.sendCommunicationPure(
        communicationId
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async receiptsReportPure({ commit }, { communicationId }) {
    try {
      const response = await services.CommunicationService.receiptsReportPure(
        communicationId
      );
      const blobURL = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = "RecipientsReport.pdf";
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {
        messageText: "page.communication.details.norecipients"
      });
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
