/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    filters: {
      search: "",
      groupStatus: null
    },
    groups: [],
    currentGroup: {},
    groupUsers: [],
    managementFilters: {
      search: "",
      sortBy: "fullName",
      sortDesc: false
    }
  };
};
const state = getDefaultState();

const getters = {
  getFilterSearch: state => state.filters.search,
  getFilterGroupStatus: state => state.filters.groupStatus,

  getGroups: state => state.groups,

  getCurrentGroup: state => state.currentGroup,
  getGroupUsers: state => state.groupUsers,

  getManagementSearch: state => state.managementFilters.search,
  getManagementSortBy: state => state.managementFilters.sortBy,
  getManagementSortDesc: state => state.managementFilters.sortDesc
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_FILTER_SEARCH(state, search) {
    state.filters.search = search;
  },
  SET_FILTER_GROUP_STATUS(state, status) {
    state.filters.groupStatus = status;
  },

  SET_GROUPS(state, groups) {
    state.groups = groups;
  },

  SET_CURRENT_GROUP(state, group) {
    state.currentGroup = group;
  },
  SET_GROUP_USERS(state, groupUsers) {
    state.groupUsers = groupUsers;
  },
  SET_MANAGEMENT_SEARCH(state, search) {
    state.managementFilters.search = search;
  },
  SET_MANAGEMENT_SORT_BY(state, sortBy) {
    state.managementFilters.sortBy = sortBy;
  },
  SET_MANAGEMENT_SORT_DESC(state, sortDesc) {
    state.managementFilters.sortDesc = sortDesc;
  }
};

const actions = {
  async retrieveGroups({ commit, getters, rootGetters }) {
    try {
      const parameters = {
        search: getters.getFilterSearch
      };
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }
      if (getters.getFilterGroupStatus) {
        parameters.status = getters.getFilterGroupStatus;
      }
      const response = await services.GroupService.retrieveGroups(parameters);
      commit("SET_GROUPS", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveGroup({ commit }, groupId) {
    try {
      const response = await services.GroupService.retrieveGroup(groupId);
      commit("SET_CURRENT_GROUP", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, { fallbackRoute: "Groups" });
      return Promise.reject();
    }
  },
  async createGroup({ commit }, { payload }) {
    try {
      await services.GroupService.createGroup(payload);
    } catch (error) {
      if (error.response && error.response.body.errorCode === 11000)
        SET_ERROR(error, {
          titleText: "dialog.error.duplicateTitle",
          messageText: "dialog.error.duplicateMessage"
        });
      return Promise.reject(error.response?.body || error);
    }
  },
  async addGroupMember({ commit }, { groupId, payload }) {
    try {
      await services.GroupService.addGroupMember(groupId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateGroup({ commit }, { groupId, payload }) {
    try {
      await services.GroupService.updateGroup(groupId, payload);
    } catch (error) {
      if (error.response && error.response.body.errorCode === 11000)
        SET_ERROR(error, {
          titleText: "dialog.error.duplicateTitle",
          messageText: "dialog.error.duplicateMessage"
        });
      return Promise.reject(error.response?.body || error);
    }
  },
  async retrieveGroupUsers({ commit, getters }) {
    try {
      const parameters = {
        groupId: getters.getCurrentGroup?._id,
        // type: "consumer",
        search: getters.getManagementSearch
      };
      const response = await services.GroupService.retrieveGroupUsers(
        parameters
      );
      commit("SET_GROUP_USERS", response);
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.group.noGroupErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async updateGroupMembers({ commit }, { groupId, payload }) {
    try {
      await services.GroupService.updateGroupMembers(groupId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async removeGroupMembers({ commit }, { groupId, payload }) {
    try {
      await services.GroupService.removeGroupMembers(groupId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async searchUsers({ commit, rootGetters }, { searchText }) {
    try {
      const parameters = {
        // groupId: getters.getCurrentGroup?._id,
        type: "consumer",
        search: searchText,
        administratorId:
          rootGetters["auth/getAdminInfo"].type === "domi"
            ? rootGetters["filter/getAdministratorSelected"]._id
            : rootGetters["auth/getAdminInfo"]._id
      };
      const response = await services.UserService.retrieveUsers(parameters);
      return response;
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.group.noGroupErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async uploadGroupMembers({ commit }, { groupId, file }) {
    try {
      await services.GroupService.uploadGroupMembers(groupId, file);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
