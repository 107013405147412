import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import router from "./router";

import general from "./store/general";
import alert from "./store/alert";
import loader from "./store/loader";
import confirm from "./store/confirm";
import user from "./store/user";
import auth from "./store/auth";
import communication from "./store/communication";
import condominium from "./store/condominium";
import issue from "./store/issue";
import maintainer from "./store/maintainer";
import domi from "./store/domi";
import admin from "./store/admin";
import consumer from "./store/consumer";
import userMaintainer from "./store/userMaintainer";
import post from "./store/post";
import filter from "./store/filter";
import info from "./store/info";
import blockList from "./store/blockList";
import groups from "./store/groups";
import parameters from "./store/parameters";
import maintainerIssues from "./store/maintainerIssues";
import notification from "./store/notification";

Vue.use(Vuex);

const storeModules = {
  general,
  alert,
  loader,
  confirm,
  blockList,
  user,
  auth,
  condominium,
  communication,
  issue,
  maintainer,
  domi,
  admin,
  consumer,
  userMaintainer,
  post,
  filter,
  info,
  groups,
  parameters,
  maintainerIssues,
  notification
};

const storeModuleKeysList = Object.keys(storeModules);

const vuexSecure = new VuexPersistence({
  storage: window.localStorage,
  modules: storeModuleKeysList,
  reducer: state => {
    const persistStoreModules = storeModuleKeysList.reduce((acc, cur) => {
      if (cur) acc[cur] = { ...state[cur] };
      return acc;
    }, {});

    return {
      ...persistStoreModules,
      post: { ...state.post, posts: null },
      issue: { ...state.issue, issues: null },
      communication: {
        ...state.communication,
        communications: null,
        userCommunications: null
      }
    };
  }
});

const actions = {
  clearAll({ commit }) {
    Object.keys(storeModules)
      .filter(storeName => storeName !== "loader" && storeName !== "general")
      .forEach(s => {
        commit(`${s}/RESET`);
      });
    window.sessionStorage.clear();
    window.localStorage.clear();
  },
  logout({ commit }) {
    commit("auth/SET_IS_LOGGED_IN", false);
    commit("auth/SET_SESSION_TOKEN", "");
    router.replace({ name: "Login" });
  }
};

export default new Vuex.Store({
  modules: storeModules,
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions,
  plugins: [vuexSecure.plugin]
});
