/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";

const getDefaultState = () => {
  return {
    logo: null,
    categories: [],
    adminLogo: null
  };
};
const state = getDefaultState();

const getters = {
  getLogo: state => state.logo,
  getCategories: state => state.categories,
  getActiveCategories: state => {
    const activeCategories = [];
    state.categories.forEach(category => {
      if (category.active) activeCategories.push(category);
    });
    return activeCategories;
  },
  getAdminLogo: state => state.adminLogo
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_ADMIN_LOGO(state, adminLogo) {
    state.adminLogo = adminLogo;
  }
};

const actions = {
  async retrieveCategories({ commit, getters, rootGetters }) {
    try {
      const parameters = {};
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }
      const response = await services.ParametersService.retrieveCategories(
        parameters
      );
      commit("SET_CATEGORIES", response);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createCategory({ commit }, categoryDetails) {
    try {
      await services.ParametersService.createCategory(categoryDetails);
    } catch (error) {
      if (error.response && error.response.body.errorCode === 11000)
        SET_ERROR(error, {
          titleText: "dialog.error.duplicateTitle",
          messageText: "dialog.error.duplicateMessage"
        });
      return Promise.reject(error.response?.body || error);
    }
  },
  async updateCategory({ commit }, { categoryId, payload }) {
    try {
      await services.ParametersService.updateCategory(categoryId, payload);
    } catch (error) {
      if (error.response && error.response.body.errorCode === 11000)
        SET_ERROR(error, {
          titleText: "dialog.error.duplicateTitle",
          messageText: "dialog.error.duplicateMessage"
        });
      return Promise.reject(error.response?.body || error);
    }
  },
  async uploadLogo({ commit }, { administratorId, payload }) {
    try {
      const response = await services.ParametersService.uploadLogo(
        administratorId,
        payload
      );
      commit("SET_ADMIN_LOGO", response.logo);
    } catch (error) {
      console.log(error?.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async assignCategory({ commit }, { categoryId, payload }) {
    try {
      await services.ParametersService.assignCategory(categoryId, payload);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  initAdminLogo({ commit, rootGetters }) {
    let admin = "";
    if (rootGetters["auth/isDomiUser"])
      admin = rootGetters["filter/getAdministratorSelected"];
    else admin = rootGetters["auth/getAdminInfo"];
    if (admin && admin.generalInfo && admin.generalInfo.logo)
      commit("SET_ADMIN_LOGO", admin.generalInfo.logo);
  },
  async retrieveAdminLogo({ commit, rootGetters }) {
    try {
      let admin = {};
      if (rootGetters["auth/isDomiUser"]) {
        admin = rootGetters["filter/getAdministratorSelected"];
        if (admin) {
          const response = await services.AdminService.retrieveAdministrator(
            admin.profile.administratorId
          );
          commit("SET_ADMIN_LOGO", response.administrator.logo || null);
        } else commit("SET_ADMIN_LOGO", "");
      } else {
        admin = rootGetters["auth/getAdminInfo"];
        commit("SET_ADMIN_LOGO", admin.generalInfo.logo || null);
      }
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error?.response?.body || error);
    }
  },
  async deleteLogo({ commit }, administratorId) {
    try {
      await services.ParametersService.deleteLogo(administratorId);
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error?.response?.body || error);
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
